<template>
  <div class='teacher'>
    <list-template
        :loading="loading"
        :current-page='page'
        :search-config='searchConfig'
        :table-config='tableConfig'
        :table-data='tableData'
        :total='total'
        hasAdd
        @onAdd='$router.push("./add")'
        @onChangePage='changeCurrentPage'
        @onHandle='tableHandle'
        @onReset='search = null'
        @onSearch='onSearch'>
		</list-template>
  </div>
</template>

<script>
import searchBlock from '@/components/SearchBlock.vue';
import {mapState} from 'vuex';
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {
		ListTemplate,
		searchBlock,
	},

	data() { // 这里存放数据
		return {
			loading: true,
			total: 0, // 总数
			search: null, // 搜索条件
			tableData: [], // 表格中的数据
			// 表格配置
			tableConfig: [{
				prop: 'goods_name',
				label: '商品名称',
			}, {
				prop: 'goods_type',
				label: '收费方式',
				render: (row) => row.goods_type === 1 ? '一次收费' : '多次收费'
			}, {
				prop: 'goods_category',
				label: '商品类型',
				// render: (row) => row.goods_category === 0 ? '学费' : row.goods_category === 1 ? '杂费' : row.goods_category === 2 ? '生活费' : row.goods_category === 3 ? '咨询费' : '培训费'
			}, {
				prop: 'goods_price',
				label: '商品价格',
			}, {
				prop: 'creator',
				label: '创建人',
			}, {
				prop: 'created_at',
				label: '创建时间',
			}, {
				width: 120,
				label: '操作',
				handle: true,
				render: () => ['编辑'],
			}],
			// 表格搜索配置
			searchConfig: [{
				prop: 'goods_name',
				placeholder: '搜索商品名称',
			}, {
				prop: 'goods_type',
				tag: 'select',
				placeholder: '筛选收费方式',
				options: [{label: '一次收费', value: 1}, {label: '多次收费', value: 2}],
			}],
		};
	},

	mounted() { // 生命周期 - 挂载完成（可以访问 DOM 元素）
		this.$store.commit('setPage', 1);
		this.getData();
  },

  activated() { // 如果页面有 keep-alive 缓存功能,这个函数会触发  进入的时候触发
    this.getData();
  },

  computed: { // 计算属性 类似于 data 概念
    ...mapState(['page']),
  },

  methods: { // 方法合集
    changeCurrentPage(e) { // 分页被更改
      this.$store.commit('setPage', e);
      this.getData();
    },

    getData() { // 获取数据
      let { search, page } = this;
      this.loading = true;
      this.$_register.get('api/recruit/goods-manage/goods-list', { params: { ...search, page } }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },

    onSearch(val) { // 搜索按钮被点击
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData();
    },

    tableHandle(row, text, index) { // 表格后面操作被点击
      this.$router.push('./edit?id=' + row.id);
      // switch (index) {
      //   case 0:
      //     this.$router.push('./edit?id=' + row.id);
      //     break;
      //   case 1:
      //     this.$confirm('是否删除当前商品', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '点错了',
      //       type: 'warning'
      //     }).then(() => {
      //       this.$_register.post('api/recruit/goods-manage/del-goods', { goods_id: row.id }).then(res => {
      //         if (res.data.status === 0) {
      //           this.$message.success('删除成功');
      //           this.getData();
      //         }
      //       })
      //     }).catch(() => {});
      //     break;
      // }
    },

  },
};
</script>

<style scoped>
</style>
